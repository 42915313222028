@import '/src/styles/global/variables';
@import '/src/styles/global/mixins';

$tile-ease: $ease-out-quint;
$tile-speed: .4s;

.gameboard {
  position: relative;
  border: 0;
  padding: 0 12px 40px;
  width: 100%;
  background-color: $white;

  @media only screen and (min-width: $small-min) {
    padding: 40px 50px;
  }

  &__column-container {
    border-radius: 3px;
    border: 1px solid $gray;
    padding: 20px;
    box-shadow: 0px 0px 4px rgba(96, 106, 112, 0.2);
    background-color: $white;
  }

  &__axis {
    @include font-size($h5-font-size);
    position: absolute;
    margin: 0;
    text-align: center;
    line-height: 1;
    font-family: $brand-font-bold;

    &--top {
      top: -28px;
      left: -2px;
      width: 100%;

      @media only screen and (max-width: $small-max) { top: 10px; }
    }

    &--right {
      top: 51.5%;
      right: -50px;
      transform: rotate(90deg) translateX(-50%);
      transform-origin: 50% 100%;

      @media only screen and (max-width: $small-max) {
        right: 10px;
        margin-top: 6px;
      }

      @media only screen and (max-width: $xs-max) { right: 0; }
    }

    &--bottom {
      bottom: -25px;
      left: 0;
      width: 100%;

      @media only screen and (max-width: $small-max) { bottom: 15px; }

      @media only screen and (max-width: $xs-max) { bottom: 10px; }
    }

    &--left {
      top: 50%;
      left: -40px;
      transform: rotate(-90deg) translateX(50%);
      transform-origin: 50% 100%;

      @media only screen and (max-width: $small-max) { left: 10px; }
    }
  }

  &__column {
    position: relative;
    padding: 10px;
    width: 50%;

    &:nth-child(1),
    &:nth-child(3) {
      border-right: 1px solid $gray;
      padding-left: 0;
    }

    &:nth-child(1),
    &:nth-child(2) {
      border-bottom: 1px solid $gray;
      padding-top: 0;
    }

    &:nth-child(2),
    &:nth-child(4) { padding-right: 0; }

    &:nth-child(3),
    &:nth-child(4) {
      padding-top: 0;
      padding-bottom: 13px;
    }

    &.is-moving {
      z-index: 999;
    }

    .gameboard__bg {
      margin-top: 10px;
    }

    &--discarded {
      border: 0 !important;/* stylelint-disable declaration-no-important */
      padding-right: 0;
      padding-left: 0;
      width: 100%;

      .gameboard__bg {

        &__content {
          margin-top: -20px;
          padding: 0 5px;
        }

        &__title {
          margin-bottom: 0;
        }
      }
    }

    /* stylelint-disable-next-line order/order */
    @media only screen and (min-width: $small-min) {
      &:nth-child(1),
      &:nth-child(2) { padding-bottom: 10px; }
    }
  }

  &__section {
    z-index: 1;
    position: relative;
    padding-bottom: 99%;
    width: 100%;
    height: 0;

    &.is-dragging-over {
      @media only screen and (max-width: $small-max) {

        .gameboard__bg { background: #d3e3f4; } /* stylelint-disable-line color-no-hex */
      }
    }
  }

  &__list {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    width: 100%;
    transition: all .2s;
    user-select: none;
    -webkit-user-drag: none;
    /* stylelint-disable-next-line property-no-unknown */
    user-drag: none;
    will-change: top, bottom;

    &--inline {
      position: relative;
      display: block;
      padding: 0;
      width: auto;
    }

    .gameboard__tiles {
      position: absolute;
      top: 20px;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0;
      padding: 20px 25px;
      overflow: auto;

      @media only screen and (min-width: $small-min) {
        margin: 14px 0 0;
        padding: 12px 19px 0;
      }
    }

    &.is-dragging {
      .gameboard__tiles {
        z-index: 222;
      }
    }

    .gameboard__tile {
      transition: transform $tile-speed $tile-ease;

      &.ng-leave { transition-duration: 0s; }

      .is-dragging & {

        // setting extra spacing for hover transition without
        // needing expensive margin transition

        @for $i from 1 through 20 {

          &:nth-child(#{$i}) {
            -webkit-font-smoothing: antialiased;
            transform: translateY(#{($i - 1) * 2}px);
          }
        }
      }
    }
  }

  &__bg {
    z-index: -1;
    position: absolute;
    top: 24px;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $blue-light-xxx;
    pointer-events: none;
    transition: transform .2s;
    will-change: transform;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: 0 2px 3px rgba(0, 0, 0, .2);
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity $tile-speed $tile-ease;
      content: '';
    }

    .gameboard__list.is-dragging + & {
      z-index: 111;
      transform: scale(1.08);

      &::before { opacity: 1; }
    }

    &--static {

      .gameboard__bg__title {
        @include font-size($h4-font-size);
        position: absolute;
        top: 50%;
        left: 0;
        margin: 0;
        padding: 0 20px;
        width: 100%;
        transform: translateY(-50%);
      }
    }

    &__content {
      position: absolute;
      top: 54%;
      left: 0;
      margin-top: -30px;
      padding: 0 20px;
      width: 100%;
      transition: all .2s;

      @media only screen and (min-width: $large-min) {
        top: 47%;
        margin-top: -62px;
        padding: 0 20px;
      }

      &--has-cards {
        @media only screen and (min-width: $small-min) { margin-top: -10px; }

        @media only screen and (min-width: $large-min) { margin-top: -20px; }
        .gameboard__bg__title { display: none; }
        .gameboard__bg__text { display: none; }

        /* stylelint-disable-next-line order/order */
        @media only screen and (max-width: $xxs-min) {
          .gameboard__bg__title { display: block; }
        }
      }
    }

    &__title {
      @include font-size($h4-font-size);
      margin-bottom: 0;
      opacity: .2;
      text-align: center;
    }

    &__text {
      @include font-size($base-font-size * 1.125);
      display: none;
      margin-top: 14px;
      opacity: .2;
      text-align: center;
      line-height: 22px;

      @media only screen and (min-width: $large-min) { display: block; }
    }

    &__count {
      display: none;
      text-align: center;
      white-space: nowrap;

      @media only screen and (max-width: $xs-max) {
        @include font-size($base-font-size);
        font-weight: 600;
      }
    }
  }

  &__title {
    z-index: 9;
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    text-align: center;
    line-height: 1;
    font-family: $brand-font-regular;

    button {
      text-decoration: none;
    }
  }

  /* stylelint-disable-next-line order/order */
  @media only screen and (max-width: $xs-max) {
    @include font-size($small-font-size);

    &__section {
      padding-bottom: 60%;

      .gameboard__list {
        top: 0;
        padding: 0;
        overflow: hidden;
        opacity: 0;

        .gameboard__tiles {
          top: 0;
          padding: 0;
        }
      }
    }

    /* stylelint-disable-next-line order/order */
    @media only screen and (max-width: $small-max) {
      &__section {
        padding-bottom: 90%;
      }

      &__column {
        padding: 5px;

        .gameboard__bg {
          margin-top: 0;
        }

        &:nth-child(3),
        &:nth-child(4) {
          padding-top: 5px;
          padding-bottom: 0;
        }
      }
    }

    &__bg {
      top: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &__title {
        @include font-size($small-font-size);
        position: relative;
        top: auto;
        margin: 10px 0 0;
        height: auto;
        text-transform: none;
        color: $black;
        opacity: 1;
        line-height: 1;
      }

      &__count {
        display: block;
        margin-top: 8px;
        margin-bottom: -5px;
        line-height: 1;
        transform: translateZ(0);

        @media only screen and (max-width: $xs-min) {
          @include font-size($base-font-size);
          font-weight: 600;
        }
      }
    }

    &__title { display: none; }

    &__menu-toggle { display: block !important; }
  }

  &__slider {
    &-empty {
      @include font-size(14);
      margin: 40px 0;
    }
  }

  &__menu-toggle {
    z-index: 999;
    position: absolute;
    top: 2px;
    right: 10px;
    display: none;
    border: 0;
    background: none;
    -webkit-appearance: none;
    appearance: none;

    @media only screen and (max-width: $xxs-min) {
      display: block;
    }

    span {
      display: inline;
      border: 0;
      padding: 0;
      min-width: 0;
      min-height: 0;
      text-decoration: underline;
      line-height: inherit;
      font-weight: 600;
      color: #037ea6;
    }
  }

}

.gameboard-container {
  padding: 30px;
  background-color: $white;

  @media only screen and (min-width: $med-min) {
    padding: 20px;
  }

  &--print {
    border: 1px solid #979797;

    @media print {
      padding: 45px 45px 0;
    }

    .gameboard {

      &__section {
        padding-bottom: 0;
        height: 100%;

        &__help {
          display: none;
        }
      }

      &__list {
        position: relative;
        height: 100%;
        min-height: 250px;

        .gameboard__cards {
          position: relative;
          top: 7px;
          padding-top: 35px;
          height: 100%;
          overflow: visible;
        }
      }

      &__bg {
        margin-bottom: 10px;

        &__content {
          position: relative;
          margin-top: 0;
          height: auto;
          transform: translateY(-47%);
        }

        &__text {
          display: none;
        }
      }
    }
  }
}
