/*
 * Font face declarations
 *
 ********************************************************************************/
 @import url("https://fast.fonts.net/lt/1.css?apiType=css&c=efc55df2-c9e0-446e-877b-07b9a75ae63a&fontids=5838705,5838721,5838769,5838785,5838801,5838817,5838915,5838931");
@font-face {
  font-family: 'proxima-nova-regular';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('../../fonts/proxima-nova-regular.woff2') format('woff2'), url('../../fonts/proxima-nova-regular.woff') format('woff');
}

@font-face {
  font-family: 'proxima-nova-bold';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('../../fonts/proxima-nova-bold.woff2') format('woff2'), url('../../fonts/proxima-nova-bold.woff') format('woff');
}

@font-face {
  font-family:'Kievit W05 Regular';
  src:url('../../fonts/5838705/5606204b-7ba7-4323-8052-0ec5c7d4dd25.woff2') format('woff2'),url('../../fonts/5838705/8df505ef-cb64-4e83-bffb-d5adfd65c5a8.woff') format('woff');
}
@font-face {
  font-family:'Kievit W05 Italic';
  src:url('../../fonts/5838721/57d401e5-9962-45ea-b036-5751e70b54c5.woff2') format('woff2'),url('../../fonts/5838721/69744585-4d63-4f4b-9c04-5d4475a61582.woff') format('woff');
}
@font-face {
  font-family:'Kievit W05 Medium';
  src:url('../../fonts/5838769/c76ae68a-0fc4-44d3-b194-f16b00b75f07.woff2') format('woff2'),url('../../fonts/5838769/0bb7fc85-d422-4976-9629-33b521a56c00.woff') format('woff');
}
@font-face {
  font-family:'Kievit W05 Medium Italic';
  src:url('../../fonts/5838785/49b37d29-1c4c-4e1e-a7b9-ade697b07c70.woff2') format('woff2'),url('../../fonts/5838785/1b726af9-f6b7-4b4d-a4ed-8cf499179529.woff') format('woff');
}
@font-face {
  font-family:'Kievit W05 Bold';
  src:url('../../fonts/5838801/3969137d-3ecd-42e1-b0e6-3a6c2039b649.woff2') format('woff2'),url('../../fonts/5838801/0d0ed892-71ad-4f79-9e6b-3f69281211d1.woff') format('woff');
}
@font-face {
  font-family:'Kievit W05 Bold Italic';
  src:url('../../fonts/5838817/a161c88a-adcd-4a5f-ac1f-f3d8323d01bb.woff2') format('woff2'),url('../../fonts/5838817/a1ec101b-aca9-40de-ab5b-635935d1fb0c.woff') format('woff');
}
@font-face {
  font-family:'Kievit W05 Black';
  src:url('../../fonts/5838915/66a87a93-dd17-4511-97e9-e3fa29cf0d5a.woff2') format('woff2'),url('../../fonts/5838915/410a861f-2bdd-4dd4-ae97-ce9b0ac9cefe.woff') format('woff');
}
@font-face {
  font-family:'Kievit W05 Black Italic';
  src:url('../../fonts/5838931/b7d8ad4e-ddf0-4de8-8e7c-db5d169007ea.woff2') format('woff2'),url('../../fonts/5838931/6436bfdd-3406-461b-b0f2-311eafe26cc4.woff') format('woff');
}