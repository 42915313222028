@import '/src/styles/global/variables';

.gameboard__list {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  width: 100%;
  transition: all .2s;
  user-select: none;
  -webkit-user-drag: none;
  /* stylelint-disable-next-line property-no-unknown */
  user-drag: none;
  will-change: top, bottom;

  &--inline {
    position: relative;
    display: block;
    padding: 0;
    width: auto;
  }

  .gameboard__cards {
    position: absolute;
    top: 20px;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 20px 25px;
    overflow: auto;

    @media only screen and (min-width: $small-min) {
      margin: 14px 0 0;
      padding: 12px 14px 0;
    }
  }

  &.is-dragging {
    .gameboard__cards {
      z-index: 222;
    }
  }

  .gameboard__card {
    transition: transform .4s cubic-bezier(.23, 1, .32, 1);

    &.ng-leave { transition-duration: 0s; }

    .is-dragging & {

      // setting extra spacing for hover transition without
      // needing expensive margin transition

      @for $i from 1 through 20 {

        &:nth-child(#{$i}) {
          -webkit-font-smoothing: antialiased;
          transform: translateY(#{($i - 1) * 2}px);
        }
      }
    }
  }
}
