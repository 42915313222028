@use "sass:math";
@import '/src/styles/global/variables';
@import '/src/styles/global/mixins';
/*
 * Modal styles
 *
 ********************************************************************************/

.app-modal {
  position: relative;
  transform: translate(0, -50%);
  top: 50%;
  max-height: 100vh;

  @media only screen and (min-width: $med-min) {
    min-width: 700px;
  }

  &__overlay {
    z-index: 4000 - 1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    inset: 0px;
    background-color: rgba(36, 36, 36, 0.7);
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }

  &__container {
    z-index: 2;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $blue-modal-background;
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s, visibility .5s;

    &.is-visible {
      opacity: 1;
      visibility: visible;
    }
  }

  &__inner {
    position: relative;
    top: 50%;
    left: 50%;
    display: flex;
    border: 20px solid $orange;
    width: 100%;
    max-width: 860px;
    max-height: calc(100% - 40px);
    background-color: #fff;
    transform: translateX(-50%);
  }

  &__form-wrapper { width: 100%; }

  &__overflow {
    height: 100%;
    overflow: auto;
  }

  &__header {
    padding: 12px 20px;
    background-color: $blue-light-xxx;

    @media only screen and (min-width: $med-min) {
      padding: 0;
      background-color: transparent;
      text-align: center;
    }
  }

  &__content {
    display: none;

    @media only screen and (min-width: $xs-min) { padding: ($base-padding * 2); }

    @media only screen and (min-width: $small-min) { padding: ($base-padding * 3) ($base-padding * 2); }

    @media only screen and (min-width: $med-min) { padding: ($base-padding * 3); }

    @media only screen and (min-width: $large-min) { padding: ($base-padding * 3) ($base-padding * 4); }

    &.is-visible { display: block; }
  }

  &__lede {
    margin: $base-padding 0 ($base-padding * 2);

    @media only screen and (max-width: $xxs-max) {
      margin: $base-padding 0;
      padding: $base-padding;
    }
  }

  &__close-button {
    position: absolute;
    top: 3px;
    right: 5px;

    @media only screen and (min-width: $small-min) {
      top: math.div($base-padding, 2);
      right: math.div($base-padding, 2);
    }

    &:focus,
    &:hover {

      button { opacity: .5; }
    }

    button {
      border-radius: 0;
      border: 0;
      padding: math.div($base-padding, 2);
      background: transparent;
      cursor: pointer;
      transition: opacity .25s;
    }

    svg { display: block; }
  }
}
