@import '/src/styles/global/variables';
@import '/src/styles/global/mixins';

.gameboard-card {
  position: relative;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .26), 0 1px 3px 0 rgba(0, 0, 0, .16);
  padding: 0 15px;
  height: 60px;
  background: $white;
  text-align: left;
  line-height: 1em;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .05), 0 3px 8px 0 rgba(0, 0, 0, .07);
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity .4s;
    content: '';
  }

  .is-dragging & {

    &::before { opacity: 1; }
  }

  &--long {
    padding-top: .813em;
    padding-bottom: .713em;
    line-height: 1.1;

    .gameboard-card__title {
      line-height: 1.2;
    }
  }

  &--large {
    height: 60px;
    min-height: 60px;
  }

  &__label {
    @include font-size($small-font-size);
  }

  &__demo-running {
    cursor: not-allowed;
    pointer-events: none;
  }

  &__svg {
    display: block;
    margin: 5px;
  }

  &__action {
    @include font-size($small-font-size);
    z-index: 3;
    cursor: pointer;
    text-decoration: underline;
    white-space: nowrap;
    font-weight: 600;
    color: $blue-light-x;

    &:active,
    &:focus,
    &:hover {
      color: $blue;
      transition: color .25s;
    }

    &-icon {
      position: absolute;
      top: -7px;
      right: -7px;
      border-radius: 50%;
      border: 2px solid $black;
      width: 24px;
      height: 24px;
      background-color: $white;
      color: $black;

      &:active,
      &:focus,
      &:hover {
        background-color: $black;
        color: $white;
        transition: background-color .25s, color .25s;
      }

      .gameboard-container--print & {
        display: none;
      }
    }

    &--modal {
      @extend .gameboard-card__action;
      @include font-size($small-font-size);
    }
  }

  &__legend {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
  }
}
