@import '/src/styles/global/variables';

.scroll-fade {
  z-index: 500;
  position: absolute;
  right: 1px;
  bottom: 0;
  left: 1px;
  width: auto;
  height: 164px;
  background: linear-gradient(180deg, rgba(247,247,248,0) 0%, rgba(247,247,248,.8) 100%);
  pointer-events: none;
  user-select: none;
}
