@import '/src/styles/global/variables';
@import '/src/styles/global/mixins';

.hero {
  position: relative;
  width: 100%;
  padding: 40px 15px;

  @media only screen and (min-width: $med-min) {
    padding: 40px;
  }

  &--home {
    height: auto;
    background-image: url('../../images/svg/hero-background.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @media only screen and (min-width: $small-min) {
      height: 452px;
    }
  }

  &--risk-tolerance {
    margin-bottom: 270px;
    background-color: $black;

    @media only screen and (min-width: $small-min) {
      margin-bottom: 135px;
    }

    @media only screen and (min-width: $med-min) {
      margin-bottom: 195px;
    }

    .hero__content {
      color: $white;
    }

    .hero__headline {
    
      h4 {
        padding: 0;

        @media only screen and (min-width: $small-min) {
          padding: 42px 0;
        }
      }
    }
  }

  &__content {
    z-index: 1;
    position: relative;
    margin: 0 auto;
    padding: 50px 20px;
    max-width: 1124px;
    height: 100%;

    .hero--home & {
      background-color: $white;
    }

    @media only screen and (min-width: $small-min) {
      padding: 50px 36px;
    }
  }

  &__icon-row {
    display: none;

    @media only screen and (min-width: $small-min) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__headline {
    position: absolute;
    right: 0;
    left: 0;
    bottom: -250px;

    @media only screen and (min-width: $xs-min) {
      bottom: -130px;
    }

    @media only screen and (min-width: $med-min) {
      bottom: -175px;
    }

    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px 30px;
      color: $black;
    }
  }
}
