/*
 * Button styles
 *
 ********************************************************************************/

.btn {
  @include line-height(16, 50);

  display: inline-block;
  border-radius: 0;
  border: 0;
  padding: 0 $base-padding;
  background: transparent;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  font-family: $brand-font-bold;
  transition: color .25s, background-color .25s;

  &--dark {
    box-shadow: inset 0 0 0 1px $black; // Box shadow allows border without affecting box model layout.
    background-color: $black;
    color: $white;

    &:focus,
    &:hover {
      background-color: $white;
      color: $black;
    }
  }

  &--light {
    box-shadow: inset 0 0 0 1px $black; // Box shadow allows border without affecting box model layout.
    background-color: $white;
    color: $black;

    &:focus,
    &:hover {
      background-color: $black;
      color: $white;
    }
  }
}

.link {
  border-radius: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;

  &--primary { color: $blue-light-x; }

  &--secondary { color: $blue; }

  &:focus,
  &:hover { text-decoration: underline; }
}
