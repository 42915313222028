@import '/src/styles/global/variables';
@import '/src/styles/global/mixins';

.toggle-container {
  @include font-size($small-font-size);

  position: relative;
  display: flex;
  justify-content: space-around;
  border-radius: 50px;
  border: 1.5px solid $gray;
  width: 100%;
  height: 41px;
  background-color: $white;
  color: $blue-dark;
  transition: color .25s ease;

  @media only screen and (min-width: $med-min) {
    height: 48px;
  }

  @media only screen and (min-width: $large-min) {
    height: 58px;
  }

  .btn-link {
    color: $blue-dark;
  }

  &__item {
    &--left,
    &--right {
      @include font-size($small-font-size);

      z-index: 2;
      width: 50%;
      line-height: normal;
      min-height: 31px;
      text-align: center;
      text-decoration: underline;
      font-family: $brand-font-regular, sans-serif;
      color: $blue-dark;
    }

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }

  &__active {
    text-decoration: none;
    font-family: 'proxima-nova-bold', sans-serif;
    transition: color .25s ease;
  }

  &__highlight {
    position: absolute;
    margin-top: 2px;
    border-radius: 50px;
    border: 1.5px solid $black;
    width: 50%;
    height: 33px;
    background-color: $white;
    transition: transform .25s ease;

    @media only screen and (min-width: $med-min) {
      height: 41px;
    }

    @media only screen and (min-width: $large-min) {
      height: 38px;
    }

    &--left {
      left: 3px;
      transform: translateX(0%);
    }

    &--right {
      left: -3px;
      transform: translateX(100%);
    }
  }
}
