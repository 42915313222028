@use "sass:math";
@import '/src/styles/global/variables';
@import '/src/styles/global/mixins';
/*
 * Footer styles
 *
 ********************************************************************************/

.footer-cta {
  padding: 116px 55px 94px;
  background-image: url('../../images/backgrounds/footer-cta-bg.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &__heading {
    @include font-size($base-spacing-value * 1.6);

    @media only screen and (min-width: $xs-min) {
      @include font-size($base-spacing-value * 1.9);
    }
  }
}
