@use "sass:math";
@import '/src/styles/global/variables';
@import '/src/styles/global/mixins';

/*
 * Form styles
 *
 ********************************************************************************/

.form {

  fieldset {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: none;
  }

  .layout {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > .row {
      margin-bottom: $base-padding;
      width: 100%;
      text-align: left;

      @media only screen and (max-width: $xxs-max) { margin-bottom: math.div($base-padding, 2); }

      @media only screen and (min-width: $small-min) { width: 48%; }

      &:last-of-type { margin-top: $base-padding; }
    }
  }

  .input {
    border: 1px solid $gray;
    padding: 12px 20px;
    width: 100%;
  }

  .checkbox {
    position: relative;

    &__label {
      padding-left: 30px;
    }

    &__custom-input {
      z-index: -1;
      position: absolute;
      top: 50%;
      left: 0;
      display: inline-block;
      border: 1px solid $gray;
      margin-top: 0;
      width: 20px;
      height: 20px;
      transition: .25s;
      transform: translateY(-50%);

      &::after {
        position: absolute;
        content: '';
      }

      &.is-checked {
        border-color: $green;
        background-color: $green;

        &::after {
          bottom: 2px;
          left: 1px;
          margin: 0;
          width: 15px;
          height: 14px;
          background: url('../../images/svg/check.svg');
        }
      }
    }
  }

  .radio {
    position: relative;

    &__label {
      padding-left: 24px;
    }

    &__custom-input {
      z-index: -1;
      position: absolute;
      top: 50%;
      left: 0;
      display: inline-block;
      border-radius: 50%;
      border: 1px solid $black;
      margin-top: 0;
      width: 14px;
      height: 14px;
      transition: .25s;
      transform: translateY(-50%);
      cursor: pointer;

      &::after {
        position: absolute;
        content: '';
      }

      &.is-selected {

        &::after {
          top: 50%;
          left: 50%;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: $red;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .label {
    margin-bottom: 8px;
    font-family: $brand-font-bold;
  }

  .error {
    @include font-size($small-font-size);

    color: $red;
  }

  input[type='text'],
  input[type='email'] {
    width: 100%;
    margin-top: math.div($base-padding, 4);
    border: 1px solid $gray;
    height: 50px;
    appearance: none;

    @media only screen and (max-width: $xxs-max) {
      @include line-height(15, 40);

      height: 40px;
    }
  }

  input[type='submit'] {
    @include line-height(16, 50);

    display: inline-block;
    box-shadow: inset 0 0 0 1px $black; // Box shadow allows border without affecting box model layout.
    border-radius: 0;
    border: 0;
    padding: 0 $base-padding;
    background-color: $black;
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;
    font-family: $brand-font-bold;
    color: $white;
    transition: color .25s, background-color .25s;
    appearance: none;

    &:focus,
    &:hover {
      background-color: $white;
      color: $black;
    }
  }
}
