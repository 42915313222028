/*
 * Theme variables
 *
 ********************************************************************************/

// Breakpoints
$xxs: 375;
$xs: 480;
$small: 768;
$med: 992;
$large: 1200;
$xl: 1440;

$xxs-min: ($xxs * 1px);
$xs-min: ($xs * 1px);
$small-min: ($small * 1px);
$med-min: ($med * 1px);
$large-min: ($large * 1px);
$xl-min: ($xl * 1px);

$xxxs-max: (($xxs - 1) * 1px);
$xxs-max: (($xs - 1) * 1px);
$xs-max: (($small - 1) * 1px);
$small-max: (($med - 1) * 1px);
$med-max: (($large - 1) * 1px);
$large-max: (($xl - 1) * 1px);

$border-radius: .25rem;

// Colors
$blue-dark: #173b6b;
$blue: #0b6d65;
$blue-light-x: #037ea6;
$blue-light-xx: #CAE8F5;
$blue-light-xxx: #f2f8fA;
$aqua: #1460AA;
$teal: #2AA5D6;
$green: #289900;
$green-light: #EAF3CD;
$yellow: #fed000;
$red: #d21e05;
$pink: #F8DBEA;
$orange: #F58020;
$orange-light: #FCDFC7;
$black: #000;
$white: #fff;
$gray-darkest: #1b1b1b;
$gray: #e1e1e1;
$gray-light-x: #e5e5e5;
$gray-light-xx: #f1f1f1;
$gray-light-xxx: #f3f3f3;
$gray-light-xxxx: #f7f8f8;
$gauge-gray: #999999;


$blue-modal-background: rgba(23,59,107,.9);

// Spacing
$base-spacing-value: 20;
$base-padding: $base-spacing-value * 1px;

// Typography
$brand-font-regular: 'Kievit W05 Regular', 'Helvetica Neue', 'Helvetica', sans-serif;
$brand-font-medium: 'Kievit W05 Medium', 'Helvetica Neue', 'Helvetica', sans-serif;
$brand-font-bold: 'Kievit W05 Bold', 'Helvetica Neue', 'Helvetica', sans-serif;
$brand-font-black: 'Kievit W05 Black', 'Helvetica Neue', 'Helvetica', sans-serif;

$base-font-size: 1rem;
$h1-font-size: $base-font-size * 2.375; // 38
$h2-font-size: $base-font-size * 2; // 32
$h3-font-size: $base-font-size * 1.75; // 28
$h4-font-size: $base-font-size * 1.5; // 24
$h5-font-size: $base-font-size * 1.25; // 20
$small-font-size: $base-font-size * 0.875; // 14

// Shadows
$box-shadow-default: 0 0 20px rgba(0,0,0,.1);
$panel-shadow: 0 0 8px 0 rgba(90,100,100,.3);

// Easing functions
$ease-out-quint: cubic-bezier(.23, 1, .32, 1);
