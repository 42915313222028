@import '/src/styles/global/variables';
@import '/src/styles/global/mixins';

.gameboard-tray {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
  border: 1px solid $gray;
  padding: 20px;
  height: 456px;
  overflow-y: hidden;
  background-color: rgba(247,247,248,1);

  &::-webkit-scrollbar {
    width: 7px;
    -webkit-appearance: visibile;
  }

  &__container {
    position: relative;
    padding: 40px 24px 12px;
    width: 100%;
    background-color: $gray-light-xxxx;

    @media only screen and (min-width: $small-min) {
      padding: 0;
      height: calc(100% - 45px);
      background-color: transparent;
    }

    @media only screen and (max-width: $xxs-max) {
      .scroll-fade {
        display: none;
      }
    }

    .gameboard__list {

      .gameboard__cards {
        margin: 0;
        padding-top: 0;
      }
    }
  }

  &__empty {
    @include font-size($small-font-size);
    position: relative;
    top: 50%;
    text-align: center;
    transform: translateY(-50%);

    button {
      min-height: 20px;
    }
  }
}
