@import '/src/styles/global/variables';
@import '/src/styles/global/mixins';

.gameboard__legend {
  &::before {
    display: inline-block;
    content: '';
    width: 8px;
    height: 8px;
    margin-right: 9px;
  }

  &--financial {
    &::before {
      background-color: $orange;
    }
  }
  &--life {
    &::before {
      background-color: $teal;
    }
  }
}