@import '/src/styles/global/variables';
@import '/src/styles/global/mixins';

.card-slider-container {
  position: relative;
  padding-bottom: 30px;
  transition: opacity .3s, height .4s;

  @media only screen and (min-width: $med-min) {
    padding-bottom: 20px;
  }

  &--empty {
    height: 0;
    overflow: hidden;
  }
}

.card-slider {
  position: relative;
  margin-bottom: 10px;
  touch-action: none;

  @media only screen and (min-width: $med-min) {
    .slick-prev {
      left: -30px;
    }

    .slick-next {
      right: -30px;
    }
  }

  &__drag {
    position: absolute;
    top: 20px;
    right: 15px;
    bottom: 8px;
    left: 15px;

    &--is-dragging {
      background: rgba(#fff, .5);
    }

    .gameboard-card {
      opacity: 0;
    }
  }

  .dndDraggingSource {
    display: block;
    opacity: .5;
  }

  .slick-list {
    padding-top: 10px;
    padding-bottom: 10px;
    pointer-events: none;
  }

  .slick-slider {
    display: flex;
    align-items: center;
  }

  .slick-next,
  .slick-prev {
    border: 0;
    width: 20px;
    height: 20px;
    background: none;
    appearance: none;

    &.slick-disabled {
      pointer-events: none;
      opacity: .2;
    }

    &::before {
      display: inline-block;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      vertical-align: middle;

      content: '';
    }
  }

  .slick-next {
    right: -18px;

    &::before {
      background-image: url('../../../images/svg/chevron-right.svg');
    }
  }

  .slick-prev {
    left: -18px;

    &::before {
      background-image: url('../../../images/svg/chevron-left.svg');
    }
  }

  .slick-slide {
    padding: 0 5px;
  }

  &__counter {
    @include font-size($small-font-size);

    margin-bottom: 5px;
    text-align: center;
    color: $gray;
  }
}
