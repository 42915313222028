/*
 * Base styles
 *
 ********************************************************************************/

.container {
  margin: 0 auto;
  padding-right: $base-padding;
  padding-left: $base-padding;
  width: 100%;

  @media only screen and (max-width: $xxs-max) {
      padding-right: ($base-padding * .75);
      padding-left: ($base-padding * .75);
  }

  &--max-1000 { max-width: 1000px; }

  &--max-1100 { max-width: 1100px; }

  &--max-1300 { max-width: 1300px; }
}

// There aren't many instances where we wouldn't want auto height, and if so we can override it.
img { height: auto; }

// Only allow skip link to be visible on screen when focused.
.skip-link {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%,-101%); // 101% accounts for pixel discrepancies between browsers.
  transition: transform .2s ease-in-out;

  &:focus { transform: translate(-50%,0); }
}
