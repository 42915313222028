/*
 * Utility classes
 *
 ********************************************************************************/

// Hide visually
// Use mixin and extend to allow extend within media queries https://stackoverflow.com/a/14841491
@mixin u-sr-only {
  position: absolute;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  white-space: nowrap;
  clip: rect(0 0 0 0);
}

%u-sr-only { @include u-sr-only; }

.u-sr-only { @extend %u-sr-only; }

// Hide visually at breakpoints
.u-hidden {

  &-600-max {

    @media only screen and (max-width: 600px) { @include u-sr-only; }
  }
}

.bg {

  &-dark-blue {
    background-color: $blue-dark;

    @media print {
      background-color: transparent;
    }
  }

  &-blue-light-xxx {
    background-color: $blue-light-xxx;

    @media print {
      background-color: transparent;
    }
  }

  &-aqua {
    background-color: $aqua;

    @media print {
      background-color: transparent;
    }
  }

  &-green-light {
    background-color: $green-light;

    @media print {
      background-color: transparent;
    }
  }

  &-pink {
    background-color: $pink;

    @media print {
      background-color: transparent;
    }
  }

  &-orange-light {
    background-color: $orange-light;

    @media print {
      background-color: transparent;
    }
  }

  &-gray {
    background-color: $gray-light-xxx;

    @media print {
      background-color: transparent;
    }
  }
}

.text-white {
  color: $white;
}

.list-unstyled {
  margin: 0;
  padding: 0;
  list-style: none;
}

.text-center {
  text-align: center;
}

.center {
  margin: 0 auto;
}