/*
 * Base typographic styles
 *
 ********************************************************************************/

html,
body {
  font-family: $brand-font-regular;
  font-size: $base-font-size;
}

h2,
h5,
h6,
.h2,
.h5,
.h6 {
  font-family: $brand-font-bold;
}

h1,
.h1 {
  @include font-size($h1-font-size * 0.842105);
  font-family: $brand-font-black;

  @media only screen and (min-width: $med-min) {
    @include font-size($h1-font-size);
  }
}

h2,
.h2 {
  @include font-size($h2-font-size * 0.6875);

  @media only screen and (min-width: $med-min) {
    @include font-size($h2-font-size);
  }
}

h3,
.h3 {
  @include font-size($h3-font-size * 0.64);
  font-family: $brand-font-medium;

  @media only screen and (min-width: $med-min) {
    @include font-size($h3-font-size);
  }
}

h4,
.h4 {
  @include font-size($h4-font-size * 0.625);

  @media only screen and (min-width: $med-min) {
    @include font-size($h4-font-size);
  }
}

h5,
.h5 {
  @include font-size($h5-font-size * 0.75);

  @media only screen and (min-width: $med-min) {
    @include font-size($h5-font-size);
  }
}

h6,
.h6,
p {
  @include font-size($base-font-size * 0.9375);

  @media only screen and (min-width: $med-min) {
    @include font-size($base-font-size);
  }
}

small,
.small {
  @include font-size($small-font-size);
}

.fw-normal {
  font-family: $brand-font-regular;
}

.fw-bold {
  font-family: $brand-font-bold;
}
