@use "sass:math";
@import '/src/styles/global/variables';
@import '/src/styles/global/mixins';

/*
 * Header styles
 *
 ********************************************************************************/

.feature-callout {
  padding: 30px 0 50px;

  &__heading {
    font-family: $brand-font-regular;
    margin-top: 38px;
  }

  .svg-wrapper {
    padding-top: 38px;
  }
}
