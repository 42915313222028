@import '/src/styles/global/variables';
@import '/src/styles/global/mixins';

.questionnaire {

  &__footer {
    border-top: 1px solid $gray-light-x;
    padding: 3rem 0;
  }
}
