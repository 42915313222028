@use "sass:math";
@import '/src/styles/global/variables';
@import '/src/styles/global/mixins';

$gauge-xsmall-width: 40px;
$gauge-small-width: 100px;
$gauge-medium-width: 200px;
$gauge-large-width: 350px;
$gauge-legend-xsmall-width: 56px;
$gauge-legend-small-width: 112px;
$gauge-legend-medium-width: 220px;
$gauge-legend-large-width: 280px;

$gauge-arc-bg: #f2f2f2;
$gauge-bg: #fff;
$gauge-red: rgba(218, 51, 54, 1);
$gauge-yellow: rgba(254, 208, 0, 1);
$gauge-green: rgba(170, 208, 55, 1);
$gauge-red-legend: rgba(218, 51, 54, .3);
$gauge-yellow-legend: rgba(254, 208, 0, .3);
$gauge-green-legend: rgba(170, 208, 55, .3);

@mixin make-gauge($width: 200px) {

  $legend-width: round($width * 1.1);
  $legend-diff: $legend-width - $width;
  $arc-width: round($width * .6);
  $pointer-length: round($arc-width * .38);
  $pointer-width: round($width * .1);
  $pointer-arm-width: round($pointer-width * .8);

  position: relative;
  margin-right: $legend-diff;
  margin-left: $legend-diff;
  width: $width;
  max-width: $width;

  .gauge {
    &__legend {
      top: math.div(-$legend-diff, 2);
      left: math.div(-$legend-diff, 2);

      &__section {
        left: math.div($legend-width, 2);
        width: math.div($legend-width, 2);
        height: $legend-width;

        &::before {
          left: math.div(-$legend-width, 2);
          width: math.div($legend-width, 2);
          height: $legend-width;
        }
      }
    }

    &__label {
      @include font-size($h3-font-size);
      color: $gauge-gray;
      position: absolute;

      &--alert {
        top: 60px;
        left: -70px;
      }

      &--warning {
        top: -60px;
        left: 50%;
        transform: translateX(-50%);
      }

      &--good {
        top: 60px;
        right: -100px;
      }

    }
    
    @media only screen and (max-width: $small-min) {
      &__label--alert,
      &__label--good {
        top: 30px;
      }
    }

    &__image {
      width: $width;
      height: $width / 2;
    }

    &__arc {
      width: $width;
      height: ($width / 2) + 1;

      &::before {
        margin-left: round($width * -.3);
        width: round($width * .6);
        height: round($width * .3);
      }
    }

    &__mask {
      width: $width;
      height: $width;

      &::before {
        width: $width + 2;
        height: ($width / 2) + 2;
      }
    }

    &__pointer {
      bottom: -$pointer-width / 2;
      width: $pointer-width;
      height: $pointer-width;

      &::before {
        top: ($pointer-width - $pointer-arm-width) / 2;
        left: -$pointer-length;
        border-width: $pointer-arm-width / 2;
        border-right-width: $pointer-length;
      }
    }
  }
}

.gauge {
  @include make-gauge($gauge-medium-width);

  &--xs {
    @include make-gauge($gauge-xsmall-width);
  }

  &--sm {
    @include make-gauge($gauge-small-width);
  }

  &--lg {
    @include make-gauge($gauge-large-width);
  }

  @media only screen and (max-width: $small-min) {
    @include make-gauge($gauge-medium-width);
  }

  &__wrapper {
    width: fit-content;
    margin: 0 auto;
    padding: 50px;
  }

  &__title {
    margin-bottom: 18px !important;
  }

  /* Modifiers - Gauge Types */

  &--alert {
    .gauge__arc {
      background-color: $gauge-red;
    }

    .gauge__pointer {
      background-color: $gauge-red;

      &::before {
        border-right-color: $gauge-red;
      }
    }
  }

  &--warning {
    .gauge__arc {
      background-color: $gauge-yellow;
    }

    .gauge__pointer {
      background-color: $gauge-yellow;

      &::before {
        border-right-color: $gauge-yellow;
      }
    }
  }

  &--good {
    .gauge__arc {
      background-color: $gauge-green;
    }

    .gauge__pointer {
      background-color: $gauge-green;

      &::before {
        border-right-color: $gauge-green;
      }
    }
  }

  /* Elements */

  &__legend {
    position: absolute;
    transform: translateZ(0);

    &__section {
      position: absolute;
      overflow: hidden;
      opacity: .5;
      transform-origin: left center;

      &--alert {
        transform: rotate(-90deg);

        &::before {
          background-color: $gauge-red;
          transform: rotate(58deg);
        }
      }

      &--warning {
        transform: rotate(-32deg);

        &::before {
          background-color: $gauge-yellow;
          transform: rotate(64deg);
        }
      }

      &--good {
        transform: rotate(32deg);

        &::before {
          background-color: $gauge-green;
          transform: rotate(58deg);
        }
      }

      &::before {
        position: absolute;
        border-radius: 999px 0 0 999px;
        transform-origin: right center;
        content: '';
      }
    }
  }

  &__image {
    position: relative;
    overflow: hidden;
  }

  &__arc {
    position: relative;
    border-radius: 50% 50% 50% 50% / 100% 100% 0 0;
    transition: background-color 600ms cubic-bezier(.19, 1, .22, 1);

    &::before {
      z-index: 2;
      position: absolute;
      bottom: 0;
      left: 50%;
      display: block;
      border-radius: 50% 50% 50% 50% / 100% 100% 0 0;
      background-color: $gauge-bg;
      content: '';
    }
  }

  &__mask {
    position: absolute;
    top: 1px;
    left: 0;
    background-color: transparent;
    backface-visibility: hidden;
    transform-origin: center center;
    transition: all 600ms cubic-bezier(.19, 1, .22, 1);

    &::before {
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      margin: -1px 0 0 -1px;
      border-radius: 50% 50% 50% 50% / 100% 100% 0 0;
      background-color: $gauge-arc-bg;
      content: '';
    }
  }

  &__pointer {
    z-index: 10;
    position: absolute;
    left: 50%;
    border-radius: 50%;
    transition: all 600ms cubic-bezier(.19, 1, .22, 1);

    &::before {
      position: absolute;
      border: 7px solid transparent;
      border-right: 50px solid;
      width: 0;
      height: 0;
      transition: all 600ms cubic-bezier(.19, 1, .22, 1);
      content: '';
    }
  }
}
