@import '/src/styles/global/variables';

.block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid $gray;
  border-radius: 10px;
  padding: 20px;
  min-height: 300px;
  text-align: center;

  @media only screen and (min-width: $med-min) {
    padding: 40px;
  }

  &--orange {
    border-color: $orange;
  }

  &--teal {
    border-color: $teal;
  }
}
