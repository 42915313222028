@use "sass:math";
@import '/src/styles/global/variables';
@import '/src/styles/global/mixins';
/*
 * Header styles
 *
 ********************************************************************************/

.site-header {

    &__container {
      z-index: 1000;
      position: relative;
      background-color: $white;

      @media print {
        display: none;
      }
    }

    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: ($base-padding * .75) 0;
    }

    &__logo {

        svg {
            display: block; // Removes phantom bottom margin
            width: 70px;
            height: 70px;

            @media only screen and (max-width: $xxs-max) {
                width: 46px;
                height: 46px;
            }
        }
    }

    &__link {
      @include line-height(20, 50);

      &.btn { @include line-height(16, 50); }

      &.link { color: $black; }

      @media only screen and (max-width: $xxs-max) {
        @include font-size($small-font-size);
      }

      & + & {
        margin-left: $base-padding;

        @media only screen and (max-width: $xxs-max) { margin-left: math.div($base-padding, 2); }
      }
    }
}
