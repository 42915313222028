@use "sass:math";
/*
 * Mixins
 *
 ****************************************************************************/

// Font Size - unitless | ex. @include font-size(20);
@mixin font-size($font-size: $base-spacing-value) {
  $rem: math.div($font-size, $base-spacing-value);

  font-size: $font-size;
}

// Line Height - unitless | ex. @include line-height(20, 24);
@mixin line-height($font-size, $line-height) {
  line-height: math.div($line-height, $font-size);
}

@mixin flex-row() {
  display: flex;
  &::before,
  &::after {
    content: normal !important; /* stylelint-disable-line declaration-no-important */
  }
}