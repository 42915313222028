@use "sass:math";
@import '/src/styles/global/variables';
@import '/src/styles/global/mixins';
/*
 * Footer styles
 *
 ********************************************************************************/

.site-footer {

  &__container {
    background-color: $black;
  }

  &__links-wrapper {
    @media print {
      display: none;
    }
  }

  &__disclosures {
    background-color: $gray-darkest;
    color: $white;

    @media print {
      @include font-size(10px);
    }
  }

    &__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: ($base-padding * 1.25) 0;

    @media only screen and (max-width: $xs-max) { flex-direction: column; }
  }

  &__logo {

    svg {
      display: block; // Removes phantom bottom margin
      width: 49px;
      height: 49px;
    }
  }

  &__content {
    display: flex;
    -webkit-font-smoothing: antialiased; // Addresses "bold" effect of light text on dark background

    @media only screen and (max-width: $xxs-max) { flex-direction: column; }

    @media only screen and (min-width: $xs-min) and (max-width: $xs-max) {
      justify-content: space-between;
      width: 100%;
    }
  }

  &__nav {

    @media only screen and (max-width: $xxs-max) {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }

  &__link,
  &__copyright {
    @include font-size($small-font-size);
    @include line-height(14, 40);

    color: $white;
  }

  &__link {
    text-decoration: none;

    &:focus,
    &:hover { text-decoration: underline; }

    & + & {

      @media only screen and (min-width: $small-min) { margin-left: math.div($base-padding, 2); }
    }
  }

  &__copyright {

    @media only screen and (max-width: $xxs-max) { margin-top: $base-padding; }

    @media only screen and (min-width: $xs-min) { margin-left: ($base-padding * 1.5); }
  }
}
