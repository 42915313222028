@import '/src/styles/global/variables';
@import '/src/styles/global/mixins';

.results {
  &__score {
    width: fit-content;
    margin: 10px auto 35px !important;
    padding: 30px 65px;
    border: 3px solid $yellow;

    @media only screen and (max-width: $xs-min) { margin: 10px 20px 35px !important; }
  }
  
  &__reset {
    margin: 15px 0;

    button {
      position: relative;
    }
  }

  &__form {
    max-width: $large-min;
    margin: 85px auto;
    padding: 85px;
    background-color: $gray-light-xxxx;

    @media only screen and (max-width: $large-min) { margin: 85px 15px !important; }
  }
  
  &__form-wrapper {
    max-width: 770px;
    margin: 0 auto;
  }
  
  &__form-heading {
    margin-bottom: 65px !important;
  }
}